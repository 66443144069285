import './App.css';

function App() {
  return (
    <div>
      <h1>benjih</h1>
      <div id="social">
        <a target="_blank" rel="noreferrer" href="//www.twitter.com/_benjih">
          <i class="fa fa-twitter" aria-hidden="true"></i>
        </a> <a target="_blank" rel="noreferrer" href="//www.github.com/benjih">
          <i class="fa fa-github" aria-hidden="true"></i>
        </a>
      </div>
      <div id="border"></div>
      <div id="content">
        <h2>Me stuff</h2>
        <p>Currently pushing games with <a target="_blank" rel="noreferrer" href="//cauldron.co">Cauldron</a> and agendas with <a target="_blank" rel="noreferrer" href="//fellasoft.com">FELLASOFT</a>.</p>
        <p>Previously was repairing ships with <a target="_blank" rel="noreferrer" href="//90poe.io">90poe</a>, dropping dev-ops anchors withopping anchors with <a target="_blank" rel="noreferrer" href="//ankyra.io">Ankyra</a> and testing API integrations with <a href="//spectolabs.io">SpectoLabs</a>.</p>
      </div>
      <div id="content">
        <h2>Code stuff</h2>
        <p> > <a target="_blank" rel="noreferrer" href="//github.com/SpectoLabs/hoverfly">Hoverfly</a> - an open source service API virtualization tool (project lead)</p> 
        <p> > <a target="_blank" rel="noreferrer" href="//github.com/ankyra/escape">Escape</a> - an open source service release engineering toolkit (contributions)</p> 
        <p> > <a target="_blank" rel="noreferrer" href="//play.battlesnake.com/u/benjih/barry/">Barry</a> - my Battlesnake</p>
      </div>
      <div id="content">
        <h2>Game stuff</h2>
        <p> > <a target="_blank" rel="noreferrer" href="games/ball">Generic Unity ball game</a> - first steps attempting to pick up Unity</p>
        <p> > <a target="_blank" rel="noreferrer" href="//github.com/benjih/ludum-dare-32">Unconvential Card Battles</a> - Java LWJGL Ludum Dare 32 submission</p>
        <p> > <a target="_blank" rel="noreferrer" href="//github.com/benjih/ludum-dare-28">One Shot</a> - Java LWJGL Ludum Dare 28 submission</p>
      </div>
      <div id="content">
        <h2>Music stuff</h2>
        <p>One half of the Spoopy Boys</p>
        <div id="social">
          <a target="_blank" rel="noreferrer" href="//www.youtube.com/channel/UCEm-GdlVBxniTE63KGPx82A">
            <i class="fa fa-youtube" aria-hidden="true"></i>
          </a> <a target="_blank" rel="noreferrer" href="//soundcloud.com/the-spoopy-boys">
            <i class="fa fa-soundcloud" aria-hidden="true"></i>
          </a> <a target="_blank" rel="noreferrer" href="//twitter.com/thespoopyboys">
            <i class="fa fa-twitter" aria-hidden="true"></i>
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
